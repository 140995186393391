//
// Buttons
// --------------------------------------------------
button,
.button,
input[type='submit'] {
    @include button-style($baseColor, $textColor);
    display: inline-block;
    padding: 0.625em 1.25em;
    margin: 0 0 0.625em;
    border: 0;
    appearance: none;
    outline: none;
    line-height: 100%;
    cursor: pointer;
    text-decoration: none;
    font-family: inherit;
    font-size: 1rem;

    &.huge {
        font-size: 1.25rem;
        font-weight: 600;
        padding: 0.625em 2em;
    }
}

button.primary,
.button-primary,
input.button-primary {
    @include button-style($primaryColor, $white);
}

button.secondary,
.button-secondary,
input.button-secondary {
    @include button-style($secondaryColor, $white);
}

button.facebook,
.button-facebook,
input.button-facebook {
    @include button-style($socialFacebook, $white);
    padding-left: 0.6em;

    &.disabled {
        background: #9099ae;
        color: $white;
        cursor: default;
    }
}

button.twitter,
.button-twitter,
input.button-twitter {
    @include button-style($socialTwitter, $white);

    &.disabled {
        background-color: #aebcc7;
        color: $white;
        cursor: default;
    }
}

@mixin button-arrow($arrowColour: #000) {
    @include font-style-menu-item;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    &::after {
        content: get-icon-arrow($arrowColour);
        display: inline-block;
        width: 300px;
        height: 300px;
        max-width: 15px;
        max-height: 26px;
        margin-left: 7px;
    }

    &:hover {
        text-decoration: none;
    }

    @include respond-to('mobile') {
        &::after {
            height: 23px;
        }
    }
}

@mixin button-arrow-large {
    @include font-style-menu-item;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;

    &::after {
        content: get-icon-arrow-large($black);
        display: inline-block;
        width: 58px;
        height: 58px;
        margin-left: 7px;
        transform: rotate(-45deg);
        margin-top: -5px;
    }

    &:hover {
        text-decoration: none;
    }
}

@mixin button-underline($color: $textColor) {
    @include font-style-b1;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    color: $color;
    border-bottom: solid 1px $color;

    &:hover {
        text-decoration: none;
    }
}

@mixin button-underline-regular($color: $textColor) {
    @include font-style-b1;
    display: inline-block;
    text-transform: uppercase;
    text-decoration: none;
    color: $color;
    border-bottom: solid 2px $color;
    font-weight: 400;

    &:hover {
        text-decoration: none;
    }
}
