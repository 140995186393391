.logo-ticker {
    border-top: solid 1px $black;
    border-bottom: solid 1px $black;
    height: 153px;

    &__grid {
        display: flex;
    }

    &__label {
        @include font-style-h2;
        border-right: solid 1px $black;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
    }

    .swiper-wrapper {
        transition-timing-function: linear;
        height: auto;
    }

    &__slide {
        width: auto;
        display: flex;
        align-items: center;
        height: 153px;

        img {
            max-width: 75%;
            height: auto;
        }
    }

    @include respond-to('tablet') {
        height: 123px;

        &__label {
            padding-left: divide-base-horizontal-padding-tablet(30px);
            padding-right: divide-base-horizontal-padding-tablet(30px);
        }

        &__slide {
            height: 123px;

            img {
                max-width: 180px;
                height: auto;
            }
        }
    }

    @include respond-to('mobile') {
        height: auto;

        &__label {
            padding-left: divide-base-horizontal-padding-mobile(30px);
            padding-right: divide-base-horizontal-padding-mobile(30px);
            display: block;
        }

        &__grid {
            display: block;
        }

        &__label {
            padding-top: 27px;
            text-align: center;
            border-right: 0;
        }

        &__slide {
            height: 85px;

            img {
                max-width: 180px;
            }
        }
    }
}
