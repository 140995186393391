.ticker {
    border-top: solid 1px $black;
    border-bottom: solid 1px $black;
    padding-top: 10px;
    padding-bottom: 7px;

    &__text {
        width: auto;
        font-size: 4.9375rem;
        font-weight: 300;
        text-transform: uppercase;
        white-space: nowrap;
        padding-right: 380px;
    }

    .swiper-wrapper {
        transition-timing-function: linear !important;
    }

    @include respond-to('mobile') {
        padding-top: 5px;
        padding-bottom: 2px;
        border-bottom: 0;

        &__text {
            font-size: 2.375rem;
        }
    }
}
