.sticky-logo {
    left: calc(50% - 30px);
    width: 100%;
    position: fixed !important;
    bottom: 30px;
    z-index: 5;
    transform: translateX(-50%);
    border: 0;
    text-align: right;
    pointer-events: none;

    &__link {
        max-width: 123px;
        display: inline-block;
        pointer-events: all;

        svg {
            width: 100%;
            height: auto;
        }
    }

    @include respond-to('mobile') {
        display: none;
    }
}
