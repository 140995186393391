.text-with-image-or-video {
    &__columns {
        @include flex-grid(2, 0);
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:nth-child(1) {
            padding: 40px 30px;
        }

        &:nth-child(2) {
            @include background-cover;
            @include aspect-ratio(960, 1080);
            position: relative;
            border-left: solid 1px $black;
        }
    }

    &__title {
        @include font-style-h1;
        margin-bottom: 50px;
    }

    &__text {
        @include font-style-b1;
        margin-top: auto;
        max-width: 587px;
    }

    &__button {
        @include button-underline;
        line-height: 0.8;
        margin-top: 30px;
    }

    &__thumbnail {
        @include absolute-cover;
        @include background-cover;

        &::after {
            content: get-icon-play($white);
            display: block;
            width: 126px;
            height: 126px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }

    &__video {
        @include absolute-cover;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        pointer-events: none;
    }

    &__player {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    // @include respond-to('tablet') {
    //     &__column {
    //         &:first-child {
    //             padding: 30px 20px;
    //         }
    //     }
    // }

    @include respond-to('mobile') {
        &__container {
            padding: divide-base-horizontal-padding-mobile(40px)
                divide-base-horizontal-padding-mobile(30px);
        }

        &__columns {
            display: block;
        }

        &__column {
            width: 100%;

            &:first-child {
                padding: 30px 20px;
                border-top: 1px solid $black;
            }

            &:nth-child(2) {
                @include aspect-ratio(380, 330);
                border-left: 0;
                border-top: solid 1px $black;
            }
        }

        &__title {
            margin-bottom: 180px;
        }

        &__button {
            margin-top: 20px;
        }
    }
}

.text-with-image-or-video--background-green {
    background-color: $green;

    .text-with-image-or-video__columns {
        @include respond-to('mobile') {
            display: flex;
            flex-direction: column-reverse;
        }
    }
}
