.template-cases-overview {
    &__grid {
        @include flex-grid(2, 0, 0);
    }

    &__column {
        flex-basis: 60%;
        overflow-x: hidden;

        &:first-child {
            flex-basis: 40%;
        }
    }

    @include respond-to('mobile') {
        &__grid {
            display: block;
        }

        &__columns {
            flex-basis: 100%;
        }
    }
}

.template-cases-overview-intro {
    min-height: calc(100vh - 57px);
    padding: 30px;
    display: flex;
    flex-direction: column;
    // border-bottom: 1px solid $black;
    position: fixed;
    width: calc(40% - 36px);

    &__title {
        @include font-style-h1;
    }

    &__text {
        @include font-style-b1;
        margin-top: auto;
        margin-bottom: 50px;
    }

    svg {
        max-width: 120px;
        height: auto;
        opacity: 0;
    }

    @include respond-to('mobile') {
        position: relative;
        min-height: 0;
        width: 100%;
        padding: 25px 20px;

        &__text {
            margin-bottom: 0;
            margin-top: 90px;
        }

        svg {
            display: none;
        }
    }
}

.template-cases-overview-cases {
    text-align: center;
    margin-right: -1px;

    &__grid {
        @include flex-grid(3, 0, 0);
        margin-bottom: 140px;
        text-align: left;
    }

    &__column {
        border-left: 1px solid $black;
        border-bottom: 1px solid $black;
        transition: all 0.8s cubic-bezier(0.25, 0.7, 0.13, 0.84);

        .card {
            transition: 0ms;
            opacity: 1;
            transform: translateY(0);
        }

        &:nth-last-child(2) {
            border-right: 1px solid $black;
        }

        &:last-child {
            border-bottom: 0;
            border-left: 0;
        }
    }

    .facetwp-load-more {
        @include font-style-b1;
        background-color: transparent;
        text-transform: uppercase;
        text-decoration: underline;
        margin-bottom: 140px;

        &:hover {
            background-color: transparent;
            text-decoration: underline;
        }
    }

    .card {
        height: 100%;
    }

    .card:hover .card__hover-image {
        opacity: 0;
    }

    @media (max-width: 1160px) {
        &__grid {
            @include flex-grid(2, 0, 0);
        }

        &__column:last-child {
            border-left: 0;
        }
    }

    @include respond-to('mobile') {
        &__grid {
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 55px;
        }

        &__column:last-child {
            border-left: 0;
        }

        &__column:nth-child(even) {
            border-right: 1px solid $black;

            &:last-child {
                border-right: 0;
            }
        }

        &__column:nth-child(1),
        &__column:nth-child(2) {
            border-top: 1px solid $black;
        }

        .facetwp-load-more {
            margin-bottom: 55px;
        }
    }
}

.template-cases-overview-cases-cta {
    @include font-style-b1;
    padding: 30px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &__text {
        max-width: 50%;
        margin-bottom: 30px;
    }

    &__link {
        color: $black;
        text-decoration: underline;
        text-transform: uppercase;
    }

    @include respond-to('mobile') {
        padding: 15px;

        &__text {
            max-width: 80%;
        }
    }
}
