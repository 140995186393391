.text-with-cards {
    padding: 100px 30px 120px;

    &__grid {
        display: flex;
    }

    &__content {
        width: 30%;
        margin-right: 30px;
        display: flex;
        flex-direction: column;
    }

    &__items {
        @include flex-grid(3, 0);
        width: 70%;

        .card {
            margin-left: -1px;
            margin-bottom: -1px;
            border: solid 1px $black;

            &:nth-child(3n + 1) {
                transition-delay: 150ms;
            }

            &:nth-child(3n + 2) {
                transition-delay: 300ms;
            }

            &:nth-child(3n + 3) {
                transition-delay: 450ms;
            }
        }
    }

    &__title {
        @include font-style-h1;
        margin-bottom: 320px;
    }

    &__text {
        @include font-style-b1;
        max-width: 413px;
    }

    &__button {
        @include button-arrow;
        color: $black;
        margin-top: auto;
    }

    &__link {
        @include button-arrow-large;
        color: $black;
        min-width: 260px;
        margin-left: 40px;
        align-self: flex-start;

        span {
            max-width: 150px;
        }
    }

    &__next {
        @include button-arrow-large;
        align-items: flex-end;
        bottom: 20px;
        right: 20px;
        color: $black;
        color: $textColor;
        margin-top: 10px;
        display: none;

        &-label {
            display: block;
            word-spacing: 999999999px;
        }

        &-text {
            @include font-style-b2;
            display: block;
            text-transform: none;
        }

        &-wrap {
            display: block;
            text-align: left;
            margin-right: 15px;
            flex-basis: 100%;
        }
    }

    @include respond-to('tablet') {
        padding: 70px 30px 80px;

        &__grid {
            // display: block;
        }

        &__items {
            @include flex-grid(2, 0);
        }
    }

    @include respond-to('mobile') {
        padding: 30px 20px 80px;

        &__grid {
            display: block;
        }

        &__title {
            margin-bottom: 80px;
        }

        &__content,
        &__items {
            width: 100%;
        }

        &__button {
            margin-top: 20px;
            display: none;
        }

        &__items {
            margin-top: 40px;
        }

        &__next {
            display: inline-flex;
            padding: 20px;
            padding-bottom: 0;

            &::after {
                transform: rotate(-45deg) translate(-14px, 0);
            }
        }
    }

    @media (max-width: 400px) {
        &__items {
            display: block;
        }
    }
}

.text-with-cards--related {
    border-bottom: solid 1px $black;

    .text-with-cards {
        &__grid {
            // align-items: stretch;
        }

        &__content {
            width: 40%;
            display: flex;
            flex-direction: column;
        }

        &__items {
            @include flex-grid(2, 0);
            width: 60%;
        }

        &__title {
            max-width: 550px;
            margin-bottom: 50px;
            width: 100%;
        }

        &__text {
            max-width: 445px;
            margin-top: auto;
            width: 100%;
        }

        @include respond-to('tablet') {
            &__grid {
                flex-flow: row wrap;
            }

            &__content {
                width: 35%;
                flex-grow: 1;
            }

            &__items {
                width: 55%;
                flex-grow: 1;
            }

            &__link {
                margin-left: auto;
                margin-right: 0;
                align-self: flex-end;
                min-width: 0;
                margin-top: 20px;
            }
        }

        @include respond-to('mobile') {
            &__grid {
                display: flex;
                flex-direction: column;
            }

            &__content {
                width: 100%;
                margin-bottom: 16px;
                margin-right: 0;
            }

            &__items {
                width: 100%;
            }

            &__link {
                right: 20px;
            }
        }
    }
}
