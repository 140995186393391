.header-basic {
    background-color: $green;

    &__columns {
        display: flex;
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:nth-child(1) {
            width: 60%;
            padding: 20px 30px 25px;
        }

        &:nth-child(2) {
            @include background-cover;
            @include aspect-ratio(759, 1006);
            width: 40%;
            border-left: solid 1px $black;
        }
    }

    &__title {
        @include font-style-h1;
        max-width: 684px;
        margin-bottom: 60px;
    }

    &__text {
        @include font-style-h2;
        max-width: 884px;
    }

    &__logo {
        margin-top: auto;
        margin-bottom: 35px;
        max-width: 250px;
    }

    &__itc-logo {
        max-width: 123px;
        margin-top: 50px;
        opacity: 0;

        svg {
            max-width: 100%;
            height: auto;
        }
    }

    &__mobile {
        display: none;
    }

    @include respond-to('mobile') {
        &__container {
            padding: 20px;
        }

        &__columns {
            display: none;
        }

        &__mobile {
            display: block;
        }

        &__column {
            padding: 20px;
            width: 100%;
            margin: 0;

            &:first-child {
                padding: 20px;
                width: 100%;
                min-height: 280px;
            }

            &:nth-child(2) {
                @include aspect-ratio(380, 285);
                width: 100%;
                margin: 0;
                border-top: solid 1px $black;
                border-bottom: solid 1px $black;
            }

            &:nth-child(3) {
                min-height: 320px;
            }
        }

        &__title {
            margin-bottom: 50px;
        }

        &__logo {
            margin-top: 20px;
            max-width: 130px;
            margin-bottom: 25px;
        }
    }
}
