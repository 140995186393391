/* stylelint-disable */
//
// Variables
// --------------------------------------------------

// Fonts
// -------------------

$headingFont: 'Everett', sans-serif;
$paragraphFont: 'Everett', sans-serif;

// Colours
// -------------------
$black: #000;
$white: #fff;

$primaryColor: #69b5e1;
$secondaryColor: #9d6ab4;

$brown: #998f84;
$brownLight: #bfbab4;

$green: #c2c4bb;

$grey: #d3d4d0;
$greyLight: #deddda;
$greyMedium: #d7d6d0;

$textColor: $black;
$baseColor: #eee;

$socialFacebook: #3b5998;
$socialTwitter: #00aced;
$socialInstagram: #3f729b;
$socialYoutube: #cd201f;

$errorColor: #a00;
$successColor: #0a0;
$actionColor: $black;
$baseFontSize: 16;
$baseDuration: 150ms;

$baseTransition: all $baseDuration ease-in-out;

// Breakpoints
// -------------------

$breakpoints: (
    'mobile': (
        max-width: 736px,
    ),
    'tablet': (
        max-width: 1024px,
    ),
    'desktop': (
        min-width: 1240px,
    ),
);

// Include gutter on outside
$gutterOnOutside: true;

// Breakpoints Grid Settings
// -------------------

$grid-settings: (
    base: (
        container-columns: 12,
        gutter: 1%,
        max-width: 100%,
    ),
    desktop: (
        container-columns: 12,
        gutter: 1%,
        max-width: 100%,
    ),
    tablet: (
        container-columns: 12,
        gutter: 1%,
        max-width: 100%,
    ),
    mobile: (
        container-columns: 1,
        gutter: 5%,
        max-width: 100%,
    ),
);
