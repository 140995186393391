@use 'sass:math';

.single-case {
    overflow-x: hidden;
}

.single-case-header {
    border-bottom: solid 1px $black;
    margin-bottom: -1px;

    &__grid {
        display: flex;
    }

    &__column {
        flex: 0 0 60%;
        min-height: 780px;
        display: flex;
        flex-direction: column;
        position: relative;

        &:last-child {
            flex-basis: 40%;
            border-left: 1px solid $black;
            padding: 18px;
        }
    }

    &__image {
        @include background-cover;
        height: 100%;
    }

    &__image--mobile {
        display: none;
    }

    &__title,
    &__text {
        @include font-style-h2;
    }

    &__collection {
        @include font-style-b1;
        margin-top: auto;
    }

    &__video {
        @include absolute-cover;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        pointer-events: none;
    }

    &__player {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @include respond-to('tablet') {
        &__title,
        &__text {
            @include font-style-h1;
            margin-bottom: 14px;
        }

        &__image {
            display: none;
        }

        &__image--mobile {
            display: block;
        }
    }

    @include respond-to('mobile') {
        &__grid {
            flex-direction: column;
        }

        &__column,
        &__image {
            min-height: 480px;
        }

        &__image {
            @include background-cover;
        }

        &__title {
            padding-top: 37px;
        }

        &__text {
            margin-bottom: 27px;
        }
    }
}

.single-case-images {
    max-width: 80%;
    margin-left: auto;
    position: relative;
    // overflow: hidden;

    &__grid {
        @include flex-grid(2, 0, 0);
        margin-right: -4px;
    }

    &__column {
        border: 1px solid $black;
        margin-left: -1px;
        margin-top: -1px;

        &--filler {
            border: 0;
        }

        &:nth-child(odd):not(:nth-child(1)) {
            &::before {
                content: '';
                position: absolute;
                left: -100%;
                width: 100%;
                border-top: 1px solid $black;
                z-index: -1;
                margin-top: -1px;
            }
        }
    }

    &__image {
        @include background-cover;
        padding-top: (math.div(30, 23)) * 100%;
        height: 100%;
    }

    @include respond-to('mobile') {
        max-width: 100%;
        display: flex;
        flex-direction: column;

        &__grid {
            @include flex-grid(1, 0, 0, column);
        }

        &__column--filler {
            display: none;
        }

        &__column:last-child {
            border-bottom: 0;
        }
    }
}

.single-case {
    .solution-header__next {
        @include button-arrow-large;
        position: absolute;
        bottom: 20px;
        right: 20px;
        color: $black;
        color: $textColor;
        margin-left: auto;
        margin-top: 10px;

        &-label {
            display: block;
        }

        &-text {
            @include font-style-b2;
            display: block;
            text-transform: none;
        }

        &-wrap {
            display: block;
            text-align: right;
            margin-right: 15px;
        }
    }

    @include respond-to('mobile') {
        .solution-header__next {
            position: relative;
            bottom: 0;
            right: 0;
            margin-top: 27px;
            margin-bottom: 50px;
        }
    }
}
