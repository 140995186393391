.error-page {
    padding: 30px;
    min-height: calc(100vh - 539px - 57px);
    display: flex;
    align-items: center;

    &__title {
        @include font-style-h1;
        margin-bottom: 0.4em;
    }

    @include respond-to('mobile') {
        padding: 15px 20px;
    }
}
