.solution-image-text {
    border-top: solid 1px $black;

    &__columns {
        display: flex;
    }

    &__column {
        &:nth-child(1) {
            @include aspect-ratio(1173, 1005);
            @include background-cover;
            width: 60%;
            border-right: solid 1px $black;
        }

        &:nth-child(2) {
            width: 40%;
            padding: 15px 18px;
        }
    }

    &__text {
        @include font-style-h1;

        &--mobile {
            display: none;
            color: $white;
        }
    }

    @include respond-to('mobile') {
        &__column {
            &:nth-child(1) {
                @include aspect-ratio(350, 310);
                display: flex;
                align-items: flex-end;
                width: 100%;
                border-right: 0;
            }

            &:nth-child(2) {
                display: none;
            }
        }

        &__text {
            display: none;
            padding: 25px 20px;

            &--mobile {
                display: block;
            }
        }
    }
}

.solution-text-image-caption {
    border-top: solid 1px $black;

    &__columns {
        display: flex;
    }

    &__column {
        &:nth-child(1) {
            width: 40%;
            padding: 23px 30px;
            display: flex;
            flex-direction: column;
        }

        &:nth-child(2) {
            @include aspect-ratio(1210, 826);
            @include background-cover;
            width: 60%;
            border-left: solid 1px $black;
            padding: 23px;
            display: flex;
            // flex-direction: column;
            align-items: flex-end;
        }
    }

    &__text {
        @include font-style-h2;
        width: 100%;
        max-width: 557px;
        margin-bottom: 50px;
    }

    &__text-bottom {
        @include font-style-b1;
        width: 100%;
        max-width: 503px;
        margin-top: auto;
    }

    &__caption {
        @include font-style-h2;
        width: 100%;
        max-width: 557px;
        color: $white;
    }

    &__mobile {
        display: none;
    }

    @include respond-to('mobile') {
        &__columns {
            display: none;
        }

        &__mobile {
            display: block;
        }

        &__column {
            width: 100%;
            padding: 25px 20px;

            &.hidden {
                display: none;
            }

            &:first-child,
            &:nth-child(2) {
                width: 100%;
                padding: 25px 20px;
            }
        }

        &__text {
            @include font-style-h1;
            margin-bottom: 100px;
        }

        &__caption,
        &__text-bottom {
            max-width: 280px;
            margin-left: auto;
        }

        &__text-bottom {
            margin-bottom: 95px;
        }
    }
}

.solution-hiw {
    padding: 33px 28px;
    background-color: #dedbd6;
    border: 1px solid $black;
    border-bottom: 0;

    &__title {
        @include font-style-h1;
    }

    &__image {
        height: auto;
        max-width: 90%;
        display: block;
        margin: 9px auto 14px;
    }

    &__image--mobile {
        display: none;
    }

    &__text {
        @include font-style-b1;
    }

    @include respond-to('tablet') {
        &__image {
            display: none;
        }

        &__image--mobile {
            display: block;
        }
    }
}
