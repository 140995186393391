//
// Typography
// --------------------------------------------------

@mixin font-style-inherit {
    p,
    li,
    a {
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
    }
}

@mixin font-style-large {
    @include font-style-inherit;
    font-size: 5rem; // 57px
    line-height: 1.192982456;
    font-weight: 300;

    @include respond-to('tablet') {
        font-size: 4rem;
    }

    @include respond-to('mobile') {
        font-size: 3rem;
        line-height: 1.233333333;
    }
}

@mixin font-style-h1 {
    @include font-style-inherit;
    font-size: 3.5625rem; // 57px
    line-height: 1.192982456;
    font-weight: 300;

    @include respond-to('tablet') {
        font-size: 2.375rem;
    }

    @include respond-to('mobile') {
        font-size: 1.875rem;
        line-height: 1.233333333;
    }
}

@mixin font-style-h2 {
    @include font-style-inherit;
    font-size: 2.1875rem; // 35px
    line-height: 1.2;
    font-weight: 300;

    @include respond-to('tablet') {
        font-size: 1.6rem; //25.5px
    }

    @include respond-to('mobile') {
        font-size: 1.0625rem; // 17px
    }
}

@mixin font-style-b1 {
    @include font-style-inherit;
    font-size: 1.5625rem; // 25px
    line-height: 1.16;
    font-weight: 300;

    @include respond-to('tablet') {
        font-size: 1.3rem; // 20px
    }

    @include respond-to('mobile') {
        font-size: 1rem; // 16px
    }
}

@mixin font-style-b2 {
    @include font-style-inherit;
    font-size: 1rem; // 16px
    line-height: 1.375;
    font-weight: 300;

    @include respond-to('mobile') {
        font-size: 0.8125rem;
    }
}

@mixin font-style-menu-item {
    @include font-style-inherit;
    font-size: 1.375rem; // 22px
    line-height: 1.181818182;
    font-weight: 300;

    @include respond-to('mobile') {
        font-size: 1.25rem;
    }
}

// Html / body
// -------------------
html {
    color: $textColor;
    font-family: $paragraphFont;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

// Headings
// -------------------

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $headingFont;
    font-weight: inherit;
}

h1 {
    @include font-style-h1;
}

h2 {
    @include font-style-h1;
}

h3 {
    @include font-style-h2;
}

h4 {
    @include font-style-h2;
}

h5 {
    @include font-style-b2;
}

h6 {
    @include font-style-b2;
}

// Paragraph
// -------------------
p {
    @include font-style-b2;
    font-family: $paragraphFont;
}

.paragraph-styles {
    p,
    ul,
    form {
        margin-top: 1em;
        margin-bottom: 1em;

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        text-decoration: underline;
    }

    blockquote {
        @include font-style-b1;
        padding: 0;
    }
}

.paragraph-styles--large {
    p,
    li {
        @include font-style-b1;
    }
}

a {
    color: $actionColor;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

// Basic Elements
// -------------------

abbr,
acronym {
    border-bottom: 1px dotted;
    cursor: help;
}

address {
    font-size: 1rem;
    font-style: italic;
}

blockquote {
    margin: 1em 0;
    padding: 0 0 0 2em;
}

cite {
    font-style: italic;
}

code {
    font-size: 0.96rem;
    font-family: monospace;
    background: $baseColor;
    border: 1px solid darken($baseColor, 5%);
    padding: 0.125em 0.25em;
    line-height: 150%;
}

em,
i {
    font-style: italic;
}

strong,
b {
    font-weight: bold;
}

hr {
    outline: none;
    border: 0;
    margin: 1em 0;
    padding: 0;
    border-top: 1px solid $baseColor;
}

ol,
ul {
    margin: 1em 0;
    padding: 0 0 0 2em;

    li {
        font-size: 1rem;
        list-style-position: outside;
    }
}

ul li {
    list-style: disc;
}

ol li {
    list-style: decimal;
}

// Form
// -------------------

form {
    margin: 1em 0;
}

// Descriptions
// -------------------

dl {
    margin: 1em 0;

    dt {
        font-weight: bold;
    }

    dd {
        margin: 0 0 0.5em;
    }
}

// Tables
// -------------------

table {
    width: 100%;
    margin: 1em 0;

    th {
        padding: 0.5em;
        border: 1px solid $baseColor;
        background: lighten($baseColor, 3.5%);
        text-align: left;
        font-weight: bold;
    }

    td {
        padding: 0.5em;
        border: 1px solid $baseColor;
    }
}
