.image-with-text {
    @include background-cover;
    padding-bottom: 60px;
    padding-left: 30px;
    padding-right: 30px;

    &__content {
        max-width: 833px;
        padding-top: 35%;
    }

    &__text {
        @include font-style-h1;
        color: $white;

        a {
            color: $white;
        }
    }

    &__button {
        @include button-underline-regular($white);
        margin-top: 40px;
    }

    .home & {
        border-bottom: 1px solid $black;
    }

    @include respond-to('mobile') {
        min-height: 100vh;
        display: flex;
        padding: 0 20px 30px;

        &__content {
            margin-top: auto;
        }

        &__button {
            margin-top: 30px;
        }
    }
}
