body {
    padding-top: 56px;

    &.home {
        padding-top: 0;
    }
}

.home:not(.main-menu-menu-active) .main-menu {
    &:not(.has-background) {
        .main-menu__container {
            border-color: $white;
        }

        .main-menu__link {
            @include button-arrow(#fff);
            color: $white;
        }

        .main-menu__mobile-logo path {
            fill: $white;
        }
    }
}

.main-menu {
    background-color: $white;
    position: fixed;
    z-index: 100;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 200ms ease;
    overflow-y: auto;

    &__mobile-logo {
        display: none;
        width: 37px;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);

        svg {
            height: auto;
            width: 100%;
        }
    }

    .admin-bar & {
        top: 32px;
    }

    .home & {
        background-color: transparent;
    }

    &.has-background,
    .main-menu-menu-active & {
        background-color: $white;
    }

    &__container {
        padding: 0 30px;
        border-bottom: solid 1px $black;
    }

    &__list {
        padding: 0;
        margin: 0;
        min-height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__item {
        list-style: none;
        width: 60%;

        &:nth-child(2) {
            width: 20%;
            padding-left: 20px;
        }

        &:nth-child(3) {
            width: 20%;
            text-align: right;
        }
    }

    &__link {
        @include button-arrow;
        color: $black;

        &::after {
            transition: all 0.15s ease-in-out;
        }

        &.toggle-main-menu {
            background-color: transparent;
            padding: 0;
            margin: 0;

            &.is-active::after {
                transform: rotate(90deg);
            }
        }

        &--active::after {
            transform: rotate(90deg);
        }

        @media (hover: hover) {
            &:hover::after,
            &:focus:not(.toggle-main-menu)::after,
            &--active::after {
                transform: rotate(90deg);
            }
        }
    }

    &__b-corp {
        max-width: 100px;
        margin-top: auto;
        padding-top: 30px;
    }

    @include respond-to('tablet') {
        &__container {
            padding: 0 divide-base-horizontal-padding-tablet(30px);
        }
    }

    @include respond-to('mobile') {
        &__mobile-logo {
            display: block;
        }

        &__container {
            padding: 0 divide-base-horizontal-padding-mobile(20px);
        }

        &__item {
            display: none;

            &:nth-child(1) {
                margin-left: auto;
                display: inline-block;
                text-align: right;
            }
        }
    }
}

.main-menu-menu-positioner {
    position: fixed;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    height: 100%;
    z-index: 90;
    top: 56px;
    pointer-events: none;

    &::before {
        content: '';
        display: block;
        position: absolute;
        left: -1px;
        height: 100%;
        width: 50vh;
        background-color: $white;
        transform: translateX(-100%);
        z-index: 3;
        pointer-events: none;
    }
}

.main-menu-menu {
    border: solid 1px $black;
    border-left: 0;
    border-top: 0;
    background-color: $greyLight;
    width: 40%;
    height: calc(100vh - 57px);
    padding: 44px 31px;
    position: absolute;
    left: 0;
    transition: all 200ms ease-in-out;
    transform: translateX(-100%);
    z-index: 2;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 57px);
    height: calc(var(--vh, 1vh) * 100 - 57px);
    overflow-y: auto;

    &.is-active {
        transform: translateX(0);
        pointer-events: all;
    }

    .main-menu-menu__list--submenu {
        padding: 20px 0;
        margin-top: -5px;

        .main-menu-menu__item {
            &:nth-last-child(1) {
                margin-top: 0;
            }
        }

        .main-menu-menu__link {
            @include font-style-b2;
            text-transform: none;
        }
    }

    &__link {
        @include font-style-menu-item;
        text-transform: uppercase;
        color: $black;
        margin-bottom: 5px;
    }

    .instagram-text {
        margin-top: 30px;

        a {
            color: $black;
            text-decoration: underline;
        }
    }

    @include respond-to('mobile') {
        width: 100%;
        height: calc(100vh - 57px);
        height: calc(var(--vh, 1vh) * 100 - 57px);
        border-right: 0;
        overflow-y: scroll;
        padding: 30px 20px;

        &__item {
            &:nth-last-child(1) {
                margin-top: 0;
            }
        }
    }
}

.main-menu-menu-active {
    @include respond-to('mobile') {
        overflow: hidden;
    }
}
