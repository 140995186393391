@use 'sass:math';

%template-default-page-section-defaults {
    padding: 0 30px;

    @include respond-to('mobile') {
        padding: 0 20px;
    }
}

.b-corp-header {
    padding: 30px 30px 116px;
    background-color: $greyLight;
    // padding-top: 30px;
    // padding-bottom: 116px;

    &__text {
        h1,
        h2,
        h3,
        h4,
        h5,
        p {
            @include font-style-large;
        }

        strong {
            font-weight: 500;
        }
    }

    @include respond-to('mobile') {
        padding: 20px 20px 116px;
    }
}

.b-corp-content {
    border-top: solid 1px $black;

    &__grid {
        display: flex;
    }

    &__column {
        flex-grow: 1;

        &:nth-child(1) {
            width: 66.66666%;
            border-right: solid 1px $black;
            padding: 30px;
        }

        &:nth-child(2) {
            width: 33.33333%;
            position: relative;
        }
    }

    &__title {
        @include font-style-h1;
        max-width: 484px;
        margin-bottom: 172px;
    }

    &__image {
        @include absolute-cover;
        object-fit: cover;
    }

    @include respond-to('mobile') {
        &__grid {
            display: block;
        }

        &__column {
            width: 100%;

            &:nth-child(1) {
                padding: 20px;
                width: 100%;
                border: 0;
            }

            &:nth-child(2) {
                width: 100%;
            }
        }

        &__title {
            margin-bottom: 60px;
        }

        &__image {
            position: relative;
            height: auto;
        }
    }
}

.b-corp-three-images {
    border-top: solid 1px $black;

    &__grid {
        display: flex;
    }

    &__column {
        @include aspect-ratio(500, 750);
        flex-grow: 1;
        width: 33.33333%;
        border-left: solid 1px $black;
        position: relative;

        &:nth-child(1) {
            border-left: 0;
        }
    }

    &__image {
        @include absolute-cover;
        object-fit: cover;
    }

    &--secondary {
        .b-corp-three-images__column {
            @include aspect-ratio(500, 350);

            &:nth-child(1) {
                width: 40%;
            }

            &:nth-child(2) {
                width: 40%;
            }

            &:nth-child(3) {
                width: 20%;
            }
        }
    }

    @include respond-to('mobile') {
        &--secondary {
            .b-corp-three-images__grid {
                display: block;
            }

            .b-corp-three-images__column {
                width: 100%;
                border-left: 0;

                &:nth-child(1) {
                    border-top: solid 1px $black;
                }
            }

            .b-corp-three-images__column {
                &:nth-child(1),
                &:nth-child(2) {
                    @include aspect-ratio(500, 350);
                    width: 100%;
                }

                &:nth-child(3) {
                    @include aspect-ratio(500, 750);
                    width: 100%;
                }
            }
        }
    }
}

.b-corp-title-and-text {
    padding: 30px;
    border-top: solid 1px $black;
    background-color: $brownLight;
    min-height: 550px;

    &__grid {
        display: flex;
        gap: 30px;
    }

    &__column {
        flex-grow: 1;
        width: 50%;
    }

    @include respond-to('mobile') {
        padding: 20px;
        min-height: 0;

        &__grid {
            flex-direction: column;
            gap: 60px;

            > * {
                width: 100%;
            }
        }
    }
}

.b-corp-image-and-text-block {
    border-top: solid 1px $black;
    background-color: $brownLight;

    &__grid {
        display: flex;
    }

    &__column {
        flex-grow: 1;
        width: 50%;
        position: relative;

        &:nth-child(1) {
            border-right: solid 1px $black;
        }

        &:nth-child(2) {
            padding: 30px;
            display: flex;
            flex-direction: column;
            min-height: 550px;
        }
    }

    &__image {
        @include absolute-cover;
        object-fit: cover;
    }

    &__title {
        margin-bottom: 100px;
    }

    &__text {
        margin-top: auto;
    }

    &:nth-child(2n) {
        background-color: $greyLight;

        .b-corp-image-and-text-block__column {
            &:nth-child(1) {
                order: 2;
                border-right: 0;
            }

            &:nth-child(2) {
                order: 1;
                border-right: solid 1px $black;
            }
        }
    }

    @include respond-to('mobile') {
        &__grid {
            flex-direction: column;

            > * {
                width: 100%;
            }
        }

        &__column {
            &:nth-child(2) {
                padding: 20px;
                min-height: 0;
            }
        }

        &__title {
            margin-bottom: 60px;
        }

        &__image {
            height: auto;
            position: relative;
        }

        &:nth-child(2n) {
            .b-corp-image-and-text-block__column {
                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(2) {
                    order: 2;
                }
            }
        }
    }
}

.b-corp-title-and-text-secondary {
    padding: 30px;
    padding-bottom: 100px;

    &__title {
        margin-bottom: 100px;
    }

    &__text {
        @include font-style-h2;
        max-width: 1220px;

        p {
            @include font-style-h2;
        }
    }

    @include respond-to('mobile') {
        padding: 20px;

        &__title {
            margin-bottom: 60px;
        }
    }
}
