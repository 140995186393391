$delay: 300ms;
$ease: ease-in;

[data-animation] {
    transition: all $delay $ease;
    opacity: 0;

    &.animated {
        opacity: 1;
    }
}

[data-animation-delay='100'] {
    transition-delay: 100ms;
}

[data-animation-delay='200'] {
    transition-delay: 200ms;
}

[data-animation-delay='300'] {
    transition-delay: 300ms;
}

[data-animation-delay='400'] {
    transition-delay: 400ms;
}

[data-animation='fadeInUp'] {
    transform: translateY(50px);

    &.animated {
        transform: translateY(0);
    }
}

[data-animation='scaleIn'] {
    transition: transform cubic-bezier(0.25, 0.7, 0.13, 0.84) 800ms,
        opacity cubic-bezier(0.25, 0.7, 0.13, 0.84) 800ms;
    transition-delay: $delay;
    transform: scale(1.01) translateY(50px);

    &.animated {
        transform: scale(1) translateY(0);
    }
}

[data-animation='scaleInLarge'] {
    transition: transform cubic-bezier(0.25, 0.7, 0.13, 0.84) 800ms,
        opacity cubic-bezier(0.25, 0.7, 0.13, 0.84) 800ms;
    transition-delay: $delay;
    transform: scale(1.05) translateY(50px);

    &.animated {
        transform: scale(1) translateY(0);
    }
}

// Required [data-wrap="words"] on same element
// Works up to 100 words. only increase if needed.
[data-animation='fadeInWords'] {
    //   opacity: 0;
    transition: 0ms;

    span {
        transition: all 300ms ease;
        opacity: 0;
        transform: translateY(-5px);
        display: inline-block;

        &::after {
            content: ' ';
            display: inline-block;
        }

        @for $x from 2 through 100 {
            &:nth-child(#{$x}) {
                transition-delay: 75ms * ($x - 1);
            }
        }
    }

    &.animated {
        span {
            opacity: 1;
            transform: translateY(0);
        }
    }
}
