.solution-template-template-solution-advanced {
    .site-footer {
        border-top: solid 1px $black;
    }
}

.template-solution-advanced {
    .solution-fixed {
        position: fixed;
        top: 57px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        pointer-events: none;
        height: 100%;

        svg {
            max-width: 123px;
            width: 100%;
            position: absolute;
            bottom: 30px;
            left: 30px;
            height: auto;
            opacity: 0;
        }

        @include respond-to('tablet') {
            position: relative;
            top: 0;
            left: 0;
            transform: translateX(0);
            height: auto;
        }
    }

    .solution-content {
        // padding-left: 30%;
        display: flex;

        &__spacer {
            width: 38%;
            height: 1px;
            flex-grow: 1;
            min-height: 400px;
        }

        &__body {
            width: 62%;
            flex-grow: 1;
        }

        @include respond-to('tablet') {
            position: relative;
            z-index: 2;
            display: block;

            &__spacer {
                display: none;
            }

            &__body {
                width: 100%;
                background-color: $white;
            }
        }
    }

    .solution-logo {
        @include background-cover;
        width: 38%;
        background-color: $white;
        height: calc(100vh - 57px);
        padding-top: 140px;
        border-right: solid 1px $black;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        img {
            align-self: center;
        }

        @include respond-to('tablet') {
            height: auto;
            width: 100%;
            padding-top: 30px;
            padding-bottom: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 0;
            padding-left: 20px;
            padding-right: 20px;
        }

        @media (max-width: 640px) {
            img {
                max-width: 180px;

                &.full-width {
                    max-width: 100%;
                }
            }
        }

        @media (max-width: 410px) {
            img {
                max-width: 150px;

                &.full-width {
                    max-width: 100%;
                }
            }
        }
    }

    .solution-text-logo {
        margin-top: auto;
        padding-bottom: 30px;
        padding-left: 30px;
        color: $white;
        text-align: left;

        * {
            font-size: 35px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
        }

        @include respond-to('tablet') {
            padding-left: 0;
            padding-top: 80px;
        }
    }

    .solution-header {
        background-color: $greyLight;
        min-height: 544px;
        display: flex;
        flex-direction: column;
        padding: 12px 20px 20px;
        border-bottom: solid 1px $black;

        &__header {
            margin-bottom: 50px;
            display: flex;
        }

        &__next {
            @include button-arrow-large;
            color: $textColor;
            margin-left: auto;
            margin-top: 10px;
        }

        &__next-label {
            display: block;
        }

        &__next-text {
            @include font-style-b2;
            display: block;
            text-transform: none;
        }

        &__next-wrap {
            display: block;
            text-align: right;
            margin-right: 15px;
        }

        &__text {
            @include font-style-b1;
            margin-top: auto;
            max-width: 987px;
            line-height: 1.26;
        }

        @include respond-to('mobile') {
            min-height: 430px;
            border-top: 1px solid $black;

            &__next {
                display: none;
            }

            &__title {
                margin-bottom: 50px;
            }

            &__text {
                margin-top: auto;
            }
        }
    }

    .solution-benefits {
        border-bottom: solid 1px $black;
        padding: 24px 20px;
        display: flex;
        flex-direction: column;
        min-height: 480px;

        &__title {
            @include font-style-h2;
            margin-bottom: 50px;
            width: 100%;
        }

        &__text {
            @include font-style-b1;
            margin-top: auto;

            ul {
                columns: 2;
                margin: 0;
                padding: 0;
            }

            li {
                width: 100%;
                list-style: none;
                position: relative;
                padding-left: 13px;
            }

            li::before {
                content: '';
                display: block;
                height: 4px;
                width: 4px;
                background-color: $black;
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 14px;
                transform: translateY(-50%);
            }
        }

        @include respond-to('tablet') {
            &__title {
                @include font-style-h1;
            }

            li::before {
                top: 11px;
            }
        }

        @include respond-to('mobile') {
            li::before {
                top: 9px;
            }
        }
    }

    .solution-image {
        width: 100%;
        border-bottom: solid 1px $black;
    }

    .spacer {
        height: 40px;
    }

    .solution-product {
        display: flex;
        min-height: 480px;
        border-bottom: solid 1px $black;

        &__logo,
        &__content {
            width: 50%;
        }

        &__logo {
            display: flex;
            align-items: center;
            justify-content: center;

            &--mobile {
                display: none;
            }
        }

        &__logo-source {
            max-width: 400px;
            height: auto;
        }

        &__content {
            padding: 40px 40px 25px 0;
            display: flex;
            flex-direction: column;
        }

        &__title {
            @include font-style-h2;
            width: 100%;
            margin-bottom: 50px;
        }

        &__text {
            @include font-style-b1;
            width: 100%;
        }

        &__link {
            margin-top: auto;
        }

        &__link-link {
            font-size: 25px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.36;
            letter-spacing: normal;
            text-align: left;
            color: #000;
        }

        @media (max-width: 1200px) {
            &__logo-source {
                max-width: 90%;
            }
        }

        @include respond-to('mobile') {
            &__logo {
                display: none;
            }

            &__logo--mobile {
                display: block;
            }

            &__content,
            &__logo {
                width: 100%;
            }

            &__content {
                padding: 20px;
            }

            &__text {
                margin-top: 36px;
            }

            &__logo {
                margin-top: auto;
            }

            &__title {
                @include font-style-h1;
            }
        }
    }

    .solution-product-information {
        display: flex;
        border-bottom: solid 1px $black;
        margin-top: -3px;

        &__column {
            width: 50%;
            padding: 20px;
            min-height: 480px;
            position: relative;
            overflow: hidden;

            &:last-child {
                border-left: solid 1px $black;
                background-color: $greyLight;
            }
        }

        &__title {
            @include font-style-h2;
            width: 100%;
            margin-bottom: 18px;
        }

        &__video-wrapper {
            @include absolute-cover;
            display: flex;
            left: 50%;
            transform: translateX(-50%);
        }

        &__video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &__text {
            @include font-style-b2;
            width: 100%;
            text-transform: uppercase;
        }

        @include respond-to('mobile') {
            display: block;

            &__column {
                width: 100%;
                // border-bottom: solid 1px $black;
                min-height: 0;
            }

            &__column:first-child {
                min-height: 480px;
            }

            &__column:last-child {
                border-left: 0;
            }

            &__column:nth-child(1) {
                border-bottom: solid 1px $black;
            }

            &__column.mobile-hidden {
                display: none;
            }

            &__title {
                @include font-style-h1;
                margin-bottom: 18px;
            }

            &__text {
                @include font-style-b1;
            }
        }
    }

    .solution-cta {
        padding: 30px 20px;
        display: flex;
        align-items: flex-start;

        &__text {
            @include font-style-b1;
        }

        &__button {
            @include button-underline;
            margin-top: 30px;
        }

        .solution-header__next {
            margin-top: 0;
        }

        @include respond-to('mobile') {
            flex-direction: column;
            padding: 25px 20px;

            .solution-header__next {
                display: inline-flex;
                margin-top: 130px;
            }
        }
    }
}
