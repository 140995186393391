.site-footer {
    background-color: $greyMedium;
    padding: 30px 30px 20px;
    position: relative;
    z-index: 10;
    border-top: 1px solid $black;
    margin-top: -1px;

    &__grid {
        @include flex-grid(3, 0, 0);
    }

    &__column {
        &:nth-child(1) {
            flex-basis: calc(100% / 3 - 163px);
            margin-right: 163px;
        }

        &:nth-child(2) {
            flex-basis: calc(100% / 3 - 104px);
            margin-right: 124px;
        }

        &:nth-child(3) {
            flex-basis: calc(100% / 3 - 20px);
            overflow: hidden;
        }
    }

    a {
        color: $black;
    }

    .instagram-text a {
        text-decoration: underline;
    }

    &__logo {
        max-width: 100%;
        margin-top: 60px;

        svg {
            height: auto;
            max-width: 100%;
        }
    }

    &__menus {
        display: flex;
        gap: 20px;

        > * {
            flex: 0 0 33%;
        }

        // @media (max-width: 550px) {
        //     > * {
        //         flex: 0 0 50%;
        //     }
        // }
    }

    &__mobile {
        display: none;
        // margin-top: 65px;

        .site-footer__menus {
            width: 100%;
        }
    }

    &__mobile-column {
        width: 40%;
        flex-grow: 1;

        &:last-child {
            // margin-left: 30px;
        }
    }

    @media (max-width: 1360px) {
        &__grid {
            flex-flow: row wrap;
            margin-left: -60px;
        }

        &__column {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                margin-left: 0;
                margin-right: 0;
                flex-basis: 50%;
                padding-left: 60px;
            }

            &:nth-child(3) {
                margin-top: 50px;
                overflow: hidden;
            }
        }

        &__logo {
            max-width: 300px;
        }
    }

    @include respond-to('mobile') {
        padding: 20px 20px 30px;

        &__grid {
            display: none;
        }

        &__mobile {
            display: flex;
            flex-flow: row wrap;
        }

        &__logo {
            margin-top: 30px;
            max-width: 150px;
        }

        .instagram-text {
            padding-right: 15px;
        }

        &__mobile-column {
            margin-top: 65px;
            width: 100%;
        }
    }
}

.site-footer-locations {
    padding: 0;
    margin-bottom: 50px;
    margin-top: 0;

    &__location {
        @include font-style-b2;
        list-style-type: none;
        display: flex;
        justify-content: space-between;
    }
}

.newsletter {
    &__text {
        @include font-style-h2;
        margin-bottom: 40px;
    }

    &__form {
        border-bottom: 1px solid $black;
        position: relative;
    }

    &__input[type='email'] {
        background-color: transparent;
        border: 0 !important;
        width: 100%;
        margin-bottom: 0;
        padding: 30px 0;
    }

    &__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background-color: transparent;
        text-decoration: underline;
        padding-right: 0;
        font-weight: 300;

        &:hover {
            background-color: transparent;
        }
    }

    &__text-bottom {
        @include font-style-b2;
        margin-top: 50px;
    }

    @include respond-to('mobile') {
        &__text {
            margin-bottom: 20px;
        }

        &__input[type='text'] {
            padding: 12px 0;
        }

        &__text-bottom {
            font-size: 0.6875rem;
            margin-top: 30px;
        }

        &__button {
            font-size: 0.8125rem;
        }
    }
}

.site-footer-menu {
    display: flex;
    flex-direction: column;

    &__title {
        @include font-style-menu-item;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__item {
        @include font-style-b2;
    }

    @include respond-to('mobile') {
        margin-top: 45px;

        &__title {
            @include font-style-b2;
        }

        // &:last-child {
        //     margin-left: 30px;
        // }
    }
}

.site-footer__b-corp-logo {
    max-width: 147px;
    width: 100%;

    @include respond-to('mobile') {
        margin-top: 45px;
    }
}

.site-footer-partners {
    margin-top: 58px;

    &__title {
        @include font-style-b2;
        margin-bottom: 32px;
        text-transform: uppercase;
    }

    .swiper-wrapper {
        transition-timing-function: linear !important;
        height: auto;
    }

    &__items {
        // display: flex;
        // margin-right: -10px;
        // margin-top: -10px;
        // flex-wrap: wrap;
    }

    &__item {
        // padding-left: 10px;
        // padding-top: 10px;
        // flex: 0 0 auto;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        width: auto;
        display: flex;
        align-items: center;
        height: 153px;
    }

    &__logo {
        // @include background-contain;
        // height: auto;
        // width: 100%;
        // max-width: 120px;
        // min-width: 100px;
        // max-height: 100px;
        width: auto;
        height: 153px;
    }

    @include respond-to('mobile') {
        margin-top: 0;

        // &__items {
        //     display: flex;
        //     flex-wrap: wrap;
        // }

        // &__item {
        //     display: inline-block;
        //     flex: 0 0 25%;
        // }

        // &__logo {
        //     margin-bottom: 10px;
        // }
    }
}
