.card {
    @include aspect-ratio(426, 596);
    @include background-cover;
    position: relative;
    transition: all 0.8s cubic-bezier(0.25, 0.7, 0.13, 0.84);

    &__hover-image {
        @include background-cover;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: $baseTransition;
    }

    &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px 30px;
        color: $white;
    }

    &__title {
        @include font-style-b2;
        text-transform: uppercase;
    }

    &__text {
        @include font-style-b2;
        max-width: 310px;
    }

    &__link {
        @include absolute-cover;
    }

    &:hover {
        .card__hover-image {
            opacity: 1;
        }
    }

    @include respond-to('mobile') {
        &__content {
            padding: 8px 10px;
        }

        &__text {
            display: none;
        }
    }
}
