@use 'sass:math';

.template-products-header {
    background-color: $greyMedium;
    padding: 15px 30px 45px;

    &__grid {
        @include flex-grid(2, 0, 0);
        justify-content: space-between;
    }

    &__column {
        min-height: 544px;
        display: flex;
        flex-direction: column;

        &:last-child {
            max-width: 700px;
        }
    }

    &__nav {
        margin-top: auto;

        a {
            @include font-style-b1;
            color: $black;
            display: block;
        }
    }

    &__text {
        @include font-style-h2;
    }
}

.template-products-item {
    padding: 30px;
    padding-bottom: 85px;
    display: flex;
    flex-direction: column;

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 150px;
    }

    &__text {
        @include font-style-b1;
        max-width: 700px;
    }

    &__products {
        max-width: 80%;
        width: 100%;
        margin-left: auto;
        display: flex;
    }

    &__grid {
        @include flex-grid(5, 0, 0);
        justify-content: flex-end;
        flex: 1 1 100%;

        &--big {
            flex-basis: 66.7%;

            + .template-products-item__grid {
                @include flex-grid(3, 0, 0);
                justify-content: flex-start;
            }
        }
    }

    &__column {
        position: relative;
        border: 1px solid $black;
        margin-top: -1px;
        margin-left: -1px;

        &--cta {
            padding-left: 30px !important;
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            border: 0;
        }

        &--big {
            flex-basis: 100%;
        }

        &--video {
            @include aspect-ratio(313, 440);
        }
    }

    &__image {
        @include background-cover;
        padding-top: (math.div(440, 313)) * 100%;
        height: 100%;
    }

    &__next {
        @include button-arrow-large;
        align-items: flex-start;
        bottom: 20px;
        right: 20px;
        color: $black;
        color: $textColor;
        margin-top: 10px;

        &-label {
            display: block;
        }

        &-text {
            @include font-style-b2;
            display: block;
            text-transform: none;
        }

        &-wrap {
            display: block;
            text-align: left;
            margin-right: 15px;
            flex-basis: 100%;
        }
    }

    &__video {
        @include absolute-cover;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        pointer-events: none;
    }

    &__player {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @media (max-width: 1200px) {
        &__products {
            max-width: 100%;
        }

        &__grid--big + .template-products-item__grid {
            @include flex-grid(2, 0, 0);
        }
    }

    @include respond-to('tablet') {
        &__header {
            flex-direction: column;
        }

        &__title {
            @include font-style-h1;
            margin-bottom: 0.7rem;
        }
    }

    @include respond-to('mobile') {
        &__grid:not(.template-products-item__grid--big) {
            @include flex-grid(2, 0, 0);
        }

        &__products {
            flex-direction: column;
        }

        &__grid--big + .template-products-item__grid {
            // @include flex-grid(1, 0, 0, column);
        }

        &__next {
            @include button-arrow;

            &::after {
                transform: rotate(0);
            }
        }
    }

    @media (max-width: 460px) {
        &__grid:not(.template-products-item__grid--big) {
            @include flex-grid(1, 0, 0, column);
        }

        &__grid--big + .template-products-item__grid {
            @include flex-grid(1, 0, 0, column);
        }

        &__next {
            @include button-arrow-large;
        }
    }
}
