@use 'sass:math';

/* stylelint-disable */
//
// Grid Functions
// --------------------------------------------------

// Checks if gutter is set
// Returns default gutter value if gutter is not set
@function get-gutter($gutter, $default-gutter) {
    @if ($gutter == null) {
        @return $default-gutter;
    } @else {
        @return $gutter;
    }
}

// Checks if columns should be set to default columns
@function get-columns($columns, $default-container-columns, $offset) {
    @if (($columns + $offset) > $default-container-columns) {
        @return $default-container-columns;
    } @else {
        @return $columns;
    }
}

@function get-offset($columns, $default-container-columns, $offset) {
    @if (($columns + $offset) > $default-container-columns) {
        @return 0;
    } @else {
        @return $offset;
    }
}

//
// Grid
// --------------------------------------------------

$base-container-columns: map-get(
    map-get($grid-settings, base),
    container-columns
);
$base-gutter: map-get(map-get($grid-settings, base), gutter);
$base-max-width: map-get(map-get($grid-settings, base), max-width);

@mixin container($max-width: $base-max-width) {
    @include clearfix;
    // Build default base container
    max-width: $max-width;
    margin: {
        left: auto;
        right: auto;
    }

    // Loop through grid settings and build breakpoint container
    @each $breakpoint, $breakpoint-settings in $grid-settings {
        @if ($breakpoint != 'base') {
            $max-width: map-get($breakpoint-settings, max-width);

            @include respond-to($breakpoint) {
                @if ($max-width) {
                    max-width: $max-width;
                } @else {
                    // If max-width not specified use 100%;
                    max-width: 100%;
                }
            }
        }
    }
}

// Builds columns
@mixin build-column($columns, $container-columns, $offset, $gutter) {
    @if ($gutterOnOutside) {
        @include build-column-outside-gutter(
            $columns,
            $container-columns,
            $offset,
            $gutter
        );
    } @else {
        @include build-column-no-outside-gutter(
            $columns,
            $container-columns,
            $offset,
            $gutter
        );
    }
}

@mixin build-column-outside-gutter(
    $columns,
    $container-columns,
    $offset,
    $gutter
) {
    display: block;
    float: left;

    @if ($gutter == 0) {
        width: percentage(math.div($columns, $container-columns));
        margin-left: percentage(math.div($offset, $container-columns));
        margin-right: 0;
    } @else {
        width: percentage(math.div($columns, $container-columns)) - $gutter * 2;
        margin-left: percentage(math.div($offset, $container-columns)) +
            $gutter;
        margin-right: $gutter;
    }
}

@mixin build-column-no-outside-gutter(
    $columns,
    $container-columns,
    $offset,
    $gutter
) {
    display: block;
    float: left;

    width: get-columns-width($columns, $container-columns, $offset, $gutter);
    margin-right: $gutter;

    @if ($columns == $container-columns) {
        $gutter: 0;
    }

    @if ($offset > 0 and $offset < $container-columns) {
        margin-left: get-offset-width(
            $offset,
            $container-columns,
            $offset,
            $gutter
        );
    }

    &:last-child {
        margin-right: 0;
    }
}

@function get-column-width($columns, $container-columns, $offset, $gutter) {
    $width: math.div(
        (100% - ($container-columns - 1) * $gutter),
        $container-columns
    );

    @return $width;
}

@function get-columns-width($columns, $container-columns, $offset, $gutter) {
    $width: get-column-width($columns, $container-columns, $offset, $gutter);
    $columnsWidth: ($width * $columns) + $gutter * ($columns - 1);

    @return $columnsWidth;
}

@function get-offset-width($columns, $container-columns, $offset, $gutter) {
    $width: get-column-width($columns, $container-columns, $offset, $gutter);
    $columnsWidth: ($width * $columns) + $gutter * $columns;

    @return $columnsWidth;
}

@mixin omega() {
    margin-right: 0;

    @each $breakpoint, $breakpoint-settings in $grid-settings {
        @if ($breakpoint != 'base') {
            @include respond-to($breakpoint) {
                margin-right: 0;
            }
        }
    }
}

@mixin omega() {
    margin-right: 0;

    @each $breakpoint, $breakpoint-settings in $grid-settings {
        @if ($breakpoint != 'base') {
            @include respond-to($breakpoint) {
                margin-right: 0;
            }
        }
    }
}

@mixin columns($columns: $base-container-columns, $offset: 0, $gutter: null) {
    // Build default base columns
    $c-gutter: get-gutter($gutter, $base-gutter);
    @include build-column(
        $columns,
        $base-container-columns,
        $offset,
        $c-gutter
    );

    // Build other breakpoint columns

    // Loop through grid settings and build breakpoint columns
    @each $breakpoint, $breakpoint-settings in $grid-settings {
        @if ($breakpoint != 'base') {
            $default-container-columns: map-get(
                $breakpoint-settings,
                container-columns
            );
            $default-gutter: map-get($breakpoint-settings, gutter);

            $c-gutter: get-gutter($gutter, $default-gutter);
            $c-columns: get-columns(
                $columns,
                $default-container-columns,
                $offset
            );
            $c-offset: get-offset(
                $c-columns,
                $default-container-columns,
                $offset
            );

            @include respond-to($breakpoint) {
                @include build-column(
                    $c-columns,
                    $default-container-columns,
                    $c-offset,
                    $c-gutter
                );
            }
        }
    }
}

@mixin responsive-columns($breakpoint, $columns, $offset: 0, $gutter: null) {
    $default-container-columns: map-get(
        map-get($grid-settings, $breakpoint),
        container-columns
    );
    $default-gutter: map-get(map-get($grid-settings, $breakpoint), gutter);

    @include respond-to($breakpoint) {
        $c-gutter: get-gutter($gutter, $default-gutter);
        @include build-column(
            $columns,
            $default-container-columns,
            $offset,
            $c-gutter
        );
    }
}

// Custom Buttons
// -------------------

@mixin button-style($customColor, $customTextColor) {
    background-color: $customColor;
    color: $customTextColor;

    &:hover {
        background-color: darken($customColor, 3%);
        text-decoration: none;
    }

    &:active {
        background: darken($customColor, 6%);
    }

    &.disabled {
        background-color: lighten($customColor, 2.5%);
        color: darken($customColor, 15%);
        cursor: default;
    }
}

// Breakpoints
// --------------------------------------------------

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
            + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

// Shortcuts
// -------------------

@mixin size($height, $width) {
    width: $width;
    height: $height;
}

@mixin hide-text() {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
}

@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin center() {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin reset-center() {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
}

@mixin center-vertically() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin reset-center-vertically() {
    position: relative;
    top: auto;
    transform: none;
}

@mixin clearfix-float() {
    float: left;
    width: 100%;
}

@mixin iconify($content) {
    content: $content;
    font-family: 'entypo';
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    margin-left: 0.2em;
}

@mixin placeholder {
    :placeholder {
        @content;
    }

    ::placeholder {
        @content;
    }

    :input-placeholder {
        @content;
    }
}

@mixin font-size($targetSize: 16) {
    font-size: ($targetSize / $baseFontSize) + rem;
}

@mixin line-height($targetSize: 16) {
    line-height: ($targetSize / $baseFontSize) + rem;
}

// Useful Visual Elements
// -------------------

@mixin arrow-up($size: 5px, $color: #000) {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
}
@mixin arrow-down($size: 5px, $color: #000) {
    width: 0;
    height: 0;
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
}
@mixin arrow-left($size: 5px, $color: #000) {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
}
@mixin arrow-right($size: 5px, $color: #000) {
    width: 0;
    height: 0;
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
}

@mixin aspect-ratio($width, $height) {
    $ratio: math.div($width, $height);

    &:after {
        content: '';
        padding-bottom: math.div(100%, $ratio);
        display: block;
    }
}

@mixin flex-column(
    $columns,
    $h-gutter: $base-gutter,
    $v-gutter: $base-gutter,
    $grow: 0,
    $shrink: 0
) {
    flex: $grow $shrink math.div(100%, $columns);
    padding-left: $h-gutter;
    padding-top: $v-gutter;
}

@mixin flex-grid(
    $columns,
    $h-gutter: $base-gutter,
    $v-gutter: $h-gutter,
    $direction: row,
    $children: true
) {
    display: flex;
    flex-direction: $direction;
    margin-left: -$h-gutter;
    margin-top: -$v-gutter;

    @if ($columns == 1) {
        flex-wrap: nowrap;
    } @else {
        flex-wrap: wrap;
    }

    @if $children {
        > * {
            @include flex-column($columns, $h-gutter, $v-gutter);
        }
    }
}

@mixin absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin background($size, $position: center, $repeat: no-repeat) {
    background-size: $size;
    background-position: $position;
    background-repeat: $repeat;
}

@mixin background-cover {
    @include background(cover);
}

@mixin background-contain {
    @include background(contain);
}

@mixin base-element-padding {
    padding: 40px 30px;
}

@mixin base-element-horizontal-padding {
    padding: 0 30px;
}
@mixin base-element-vertical-padding {
    padding: 40px 0;
}
