%design-studio-template-section-defaults {
    padding: 0 30px;
    margin-bottom: 60px;

    @include respond-to('mobile') {
        padding: 0 20px;
        margin-bottom: 30px;
    }
}

.design-studio-header {
    @extend %design-studio-template-section-defaults;
    padding-top: 20px;

    &__grid {
        @include flex-grid(2);
    }

    &__column:first-child {
        flex-basis: 60%;
    }

    &__column:last-child {
        flex-basis: 40%;
        padding-left: 27px;
    }

    & &__title {
        @include font-style-h1;
    }

    &__text {
        @include font-style-h2;
    }

    @include respond-to('tablet') {
        &__grid {
            @include flex-grid(2);
        }
    }

    @include respond-to('mobile') {
        padding: 0;
        margin: 0;

        &__grid {
            flex-direction: column;
            padding: 20px;
            padding-bottom: 45px;
            min-height: 55vh;
        }

        &__column {
            padding-left: 0 !important;
        }

        &__column:nth-child(1) {
            margin-bottom: 50px;
        }

        &__column:nth-child(2) {
            margin-top: auto;
        }
    }
}

.design-studio-images {
    // overflow: hidden;

    .swiper-wrapper {
        transition-timing-function: linear !important;
        height: 100%;
    }

    &__grid {
        @include flex-grid(1, 0, 0);
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
    }

    &__image,
    &__video {
        @include background-cover;
        @include aspect-ratio(556, 780);
        border: 1px solid $black;
        border-right: 0;
        height: 100%;
        position: relative;
        max-width: 100%;
        width: 30%;
    }

    &__filler {
        @include aspect-ratio(190.5, 401.5);
        background-color: $green;
        display: block;
        border: 1px solid $black;
        border-right: 0;
        width: 20%;
    }

    &__video-wrapper {
        @include absolute-cover;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        pointer-events: none;
        flex-grow: 1;
    }

    &__player {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @include respond-to('mobile') {
        &__image,
        &__video {
            width: 60%;
        }

        &__filler {
            width: 40%;
        }
    }
}

.design-studio-cta {
    @extend %design-studio-template-section-defaults;
    padding-top: 40px;

    &__grid {
        @include flex-grid(2);
    }

    &__text-left {
        @include font-style-h2;
    }

    &__text-right {
        @include font-style-b1;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        max-width: max-content;

        a {
            color: $black;
            text-transform: uppercase;
        }
    }

    @include respond-to('mobile') {
        padding-top: 25px;

        &__grid {
            display: block;
        }

        &__text-right {
            margin-top: 150px;
            margin-left: 0;
        }
    }
}
