%contact-template-section-defaults {
    padding: 0 30px;
    margin-bottom: 40px;

    @include respond-to('mobile') {
        margin-bottom: 40px;
        padding: 0 20px;
    }
}

.page-template-template-contact {
    .main-menu__container {
        background-color: $brownLight;
    }

    &.main-menu-menu-active .main-menu__container {
        background-color: $white;
    }
}

.template-contact {
    &__container {
        background-color: $brownLight;
        display: flex;
        flex-direction: column;
    }

    &__grid {
        @include flex-grid(2);
    }

    .column {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 37px);
        padding-bottom: 150px;
    }

    &__bottom {
        margin-top: auto;
    }

    @media (max-width: 1230px) {
        &__grid {
            @include flex-grid(1);
        }

        .column:last-child {
            display: none;
        }
    }

    @include respond-to('mobile') {
        &__bottom {
            margin-top: 0;
        }

        .column {
            padding-bottom: 40px;
        }
    }
}

.template-contact-header {
    @extend %contact-template-section-defaults;
    margin-top: 18px;

    &__title {
        @include font-style-h1;
    }
}

.template-contact-info {
    @extend %contact-template-section-defaults;

    &__title {
        @include font-style-b1;
        margin-bottom: 18px;
    }

    &__link {
        @include font-style-h2;
        color: $black;
        text-decoration: underline;
    }

    @include respond-to('mobile') {
        &__title,
        &__link {
            margin-bottom: 10px;
            font-size: 1.25rem;
        }
    }
}

.template-contact-address {
    @extend %contact-template-section-defaults;
    padding-bottom: 80px;

    &__grid {
        @include flex-grid(2, 70px);
    }

    &__title {
        @include font-style-h2;
        margin-bottom: 26px;
    }

    &__text {
        @include font-style-b1;
    }

    @include respond-to('tablet') {
        &__title {
            @include font-style-h1;
            margin-bottom: 29px;
        }
    }

    @include respond-to('mobile') {
        margin-top: 40px;
        padding-bottom: 30px;

        &__grid {
            @include flex-grid(1, 0, 35px, column);
        }

        &__title {
            margin-bottom: 8px;
        }

        &__text {
            font-size: 1.25rem;
        }
    }
}

.template-contact-logo {
    @extend %contact-template-section-defaults;
    margin-bottom: 0;
    position: fixed;
    bottom: 50px;
    right: 0;

    svg {
        // max-width: 200px;
    }

    @include respond-to('mobile') {
        display: none;
    }
}

.template-contact-map {
    position: relative;
    height: 100%;

    &__point {
        @include font-style-b1;
        position: absolute;

        width: 12px;
        height: 12px;
        display: block;
        position: absolute;
        background-color: $black;
        border-radius: 50%;
        animation: reveal 0.5s ease forwards;
        opacity: 0;

        &::before {
            content: attr(data-time);
            text-transform: uppercase;
            top: -28px;
            right: 10px;
            position: absolute;
            width: 100%;
            min-width: 250px;
            text-align: right;
        }
    }

    &__point--seattle {
        left: 10%;
        top: 26%;
    }

    &__point--shanghai {
        top: 45%;
        left: 85%;
        animation-delay: 0.8s;
    }

    &__point--hongkong {
        top: 52%;
        left: 80%;
        animation-delay: 1.2s;
    }

    &__point--amsterdam {
        top: 24%;
        left: 33%;
        animation-delay: 0.4s;

        &::before {
            right: initial;
            left: 10px;
        }
    }

    @media (max-width: 1230px) {
        display: none;
    }
}

@keyframes reveal {
    to {
        opacity: 1;
    }
}
