@use 'sass:math';

%template-default-page-section-defaults {
    padding: 0 30px;

    @include respond-to('mobile') {
        padding: 0 20px;
    }
}

.template-default-page-header {
    @extend %template-default-page-section-defaults;
    background-color: $brownLight;
    padding-top: 15px;
    min-height: 500px;

    &__grid {
        display: flex;
        margin-left: -20px;
    }

    &__column {
        flex: 0 0 60%;
        padding-left: 20px;

        &:last-child {
            flex-basis: 40%;
        }
    }

    &__title {
        @include font-style-h1;
    }

    &__text {
        @include font-style-h1;
    }

    @include respond-to('mobile') {
        background-color: $green;
        min-height: 0;

        &__grid {
            flex-direction: column;
            min-height: 430px;
            padding-bottom: 20px;
        }

        &__column:nth-child(1) {
            margin-bottom: 50px;
        }

        &__column:nth-child(2) {
            margin-top: auto;
        }
    }
}

.template-default-page-image-with-text {
    position: relative;
    border-top: 1px solid $black;

    &__grid {
        display: flex;
    }

    &__column {
        flex: 0 0 60%;
        position: relative;
        min-height: 1000px;

        &:last-child {
            flex-basis: 40%;
            border-left: 1px solid $black;
        }
    }

    &__image {
        @include background-cover;
        padding-top: 1000px;
    }

    &__text {
        @include font-style-h2;
        padding-right: 30px;
        padding-top: 23px;
        padding-left: 20px;
    }

    &__title {
        @include font-style-h2;
        margin-bottom: -20px;
    }

    &__logo {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        max-width: 120px;
        opacity: 0;

        svg {
            max-width: 100%;
        }
    }

    &__video {
        @include absolute-cover;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        pointer-events: none;
    }

    &__player {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    @include respond-to('mobile') {
        &__grid {
            display: block;
        }

        &__logo {
            display: none;
        }

        &__column {
            min-height: auto;
        }

        &__column:last-child {
            border-left: 0;
            border-top: 1px solid $black;
            border-bottom: 1px solid $black;
        }

        &__title {
            margin-bottom: 0;
        }

        &__text {
            padding-bottom: 110px;
        }

        &__image,
        &__video {
            @include aspect-ratio(375, 460);
            padding-top: 0;
        }

        .text-with-image-or-video__video {
            position: relative;
        }
    }
}

.template-default-page-images {
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;

    &__grid {
        display: flex;
    }

    &__column {
        flex: 0 0 60%;

        &:last-child {
            flex-basis: 40%;
            border-left: 1px solid $black;
        }
    }

    &__image {
        @include background-cover;
        padding-top: 1000px;
    }

    @include respond-to('mobile') {
        &__grid {
            display: block;
        }

        &__image {
            @include aspect-ratio(374, 435);
            padding-top: 0;
        }

        &__column {
            &:last-child {
                border-left: 0;
                border-top: 1px solid $black;
            }
        }
    }
}

.template-default-image-pop-out {
    border-bottom: 1px solid $black;

    &__grid {
        @include flex-grid(3, 0);
    }

    &__column:last-child {
        flex-basis: math.div(100%, 3) * 2;
    }

    &__image-left {
        @include aspect-ratio(374, 435);
        padding: 140px 0;
        height: 100%;
        border-right: 1px solid $black;
    }

    &__diagram {
        height: 100%;
        padding: 20px 0;

        &--mobile {
            display: none;
        }
    }

    &__diagram-image {
        @include aspect-ratio(4, 2);
        margin: 0 auto;
        max-width: 800px;
        display: flex;
        height: 100%;
    }

    &__diagram--desktop {
        svg {
            // height: 100%;
            width: 100%;
            // margin-bottom: auto;
        }

        g {
            width: 1200px;
            height: 1200px;
            display: block;
        }

        .cls-1 {
            fill: #1d1d1b !important;
        }
    }

    &__diagram--mobile {
        svg {
            height: 100%;
            width: 100%;
            margin-bottom: auto;
        }

        .template-default-image-pop-out__diagram-image {
            max-width: 60%;
        }
    }

    /* stylelint-disable */
    .template-default-image-pop-out__diagram--mobile #top-center,
    .template-default-image-pop-out__diagram--mobile #top-left,
    .template-default-image-pop-out__diagram--mobile #top-right,
    .template-default-image-pop-out__diagram--mobile #bottom-left,
    .template-default-image-pop-out__diagram--mobile #bottom-center,
    .template-default-image-pop-out__diagram--mobile #bottom-right {
        pointer-events: all;
    }
    /* stylelint-enable */

    &__click-for-more-info {
        display: none;
    }

    @media (max-width: 1220px) {
        &__diagram {
            height: auto;
            padding: 20px 0;

            &--mobile {
                display: block;
            }

            &--desktop {
                display: none;
            }
        }

        &__click-for-more-info {
            display: block;
            text-align: center;
            width: 100%;
            margin: 25px 0 10px;
            font-size: 14px;
            line-height: 1.4;
        }
    }

    @include respond-to('mobile') {
        &__grid {
            @include flex-grid(1, 0, 0, column);
        }

        &__column {
            border-left: 0;
            border-right: 0;
        }

        &__image-left {
            border-right: 0;
        }

        &__column:last-child {
            border-top: 1px solid $black;
            padding-bottom: 50px;
        }
    }
}

.template-default-image-pop-out-content {
    max-width: 180px;
    position: absolute;

    &__title {
        font-size: 25px;
        font-weight: 300;
        margin-bottom: 12px;
        text-decoration: underline;
        text-decoration-thickness: 1.5px;
        text-transform: uppercase;

        span {
            flex: 0;
        }

        a {
            align-items: flex-start;
        }
    }

    &__text {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        p {
            line-height: 1.2;
            font-size: 14px;
        }
    }

    &[data-for-id='bottom-left'] {
        span {
            flex: 1 1 100%;
        }
    }

    &[data-for-id='top-left'],
    &[data-for-id='top-center'],
    &[data-for-id='top-right'] {
        transform: translateY(50%);
        transition: transform 0.2s ease-in-out 0.1s;
    }

    &[data-for-id='top-right'] {
        transform: translate(-15px, 45%);
    }

    &[data-for-id='top-left'] {
        transform: translate(-15px, 47%);
    }

    &[data-for-id='bottom-right'] {
        transform: translate(0, -18%);
    }

    &[data-for-id='bottom-left'] {
        transform: translate(-20px, -23%);
    }

    &[data-for-id='bottom-center'] {
        transform: translate(10px, 0);
    }

    &:hover {
        &[data-for-id='top-center'],
        &[data-for-id='top-left'],
        &[data-for-id='top-right'] {
            // transform: translateY(0%);
            transition: transform 0.2s ease-in-out 0s;
        }

        .template-default-image-pop-out-content__text {
            opacity: 1;
            transition: opacity 0.2s ease-in-out 0.1s;
        }
    }

    @media (min-width: 1221px) {
        &[data-for-id='top-center'] {
            transform: translate(45px, 90%);
            display: flex !important;
            max-width: 325px;

            .template-default-image-pop-out-content__text {
                margin-left: 25px;
            }
        }
    }

    @media (max-width: 1220px) {
        position: relative;
        top: 0 !important;
        left: 0 !important;
        max-width: 450px;
        margin: 0 auto;
        display: none;

        &[data-for-id='top-left'],
        &[data-for-id='top-center'],
        &[data-for-id='top-right'],
        &[data-for-id='bottom-right'],
        &[data-for-id='bottom-left'],
        &[data-for-id='bottom-center'] {
            transform: translateY(0);
        }

        &__text {
            opacity: 1;
        }

        &__title span {
            flex: auto !important;
        }
    }

    @include respond-to('mobile') {
        max-width: 400px;
    }

    @media (max-width: 440px) {
        max-width: calc(100% - 40px);
    }
}

.template-default-page-text-block {
    @extend %template-default-page-section-defaults;
    background-color: $brownLight;
    min-height: 680px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 20px;
    border-bottom: 1px solid $black;

    &__text {
        @include font-style-h2;
        max-width: 50%;
    }

    @include respond-to('mobile') {
        min-height: 0;
        padding-top: 25px;
        padding-bottom: 165px;
        justify-content: flex-start;
        background-color: $green;

        &__text {
            max-width: 100%;
        }
    }
}

.template-default-page-text-with-image {
    position: relative;
    border-bottom: 1px solid $black;

    &__grid {
        display: flex;
    }

    &__column {
        flex: 0 0 calc(#{math.div(100%, 3)} - 4px);

        &:last-child {
            flex: 0 0 math.div(100%, 3) * 2;
            border-left: 1px solid $black;
        }
    }

    &__image {
        @include background-cover;
        @include aspect-ratio(1300, 800);
    }

    &__image--mobile {
        display: none;
    }

    &__text {
        @include font-style-h2;
        padding-right: 30px;
        padding-top: 23px;
        padding-left: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-bottom: 30px;
    }

    &__title {
        @include font-style-h2;
        margin-bottom: -20px;
        margin-top: auto;
    }

    &__logo {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 30px;
        max-width: 120px;

        svg {
            max-width: 100%;
        }
    }

    @include respond-to('mobile') {
        &__grid {
            display: block;
        }

        &__text {
            @include font-style-h1;
            padding: 20px;
            padding-bottom: 168px;
        }

        &__image {
            display: none;
        }

        &__image--mobile {
            @include aspect-ratio(30, 50);
            display: block;
        }

        &__column {
            &:last-child {
                border-top: 1px solid $black;
            }
        }
    }
}

.template-default-page-text-blocks {
    @extend %template-default-page-section-defaults;

    &__grid {
        @include flex-grid(3, 22px, 0);
    }

    &__column {
        border-left: 1px solid $black;
        padding-right: 22px;
        min-height: 458px;

        &:nth-child(2) {
            .template-default-page-text-blocks__title,
            .template-default-page-text-blocks__text {
                transition-delay: 150ms;
            }
        }

        &:nth-child(3) {
            .template-default-page-text-blocks__title,
            .template-default-page-text-blocks__text {
                transition-delay: 300ms;
            }
        }

        &:first-child {
            border-left: 0;
        }
    }

    &__title {
        @include font-style-h2;
        padding-top: 22px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    &__text {
        @include font-style-b1;
    }

    @include respond-to('mobile') {
        padding: 0;

        &__grid {
            display: block;
        }

        &__column {
            border-left: 0;
            border-bottom: 1px solid $black;
            min-height: 260px;
        }

        &__title,
        &__text {
            padding-left: 20px;
        }

        &__title {
            margin-bottom: 15px;
        }
    }
}
