//
// Global Settigns
// --------------------------------------------------

* {
    box-sizing: border-box;
    font-feature-settings: 'liga' 0;
}

.container {
    @include container;

    // @media (min-width: 1921px) {
    //     border-left: solid 1px $black;
    //     border-right: solid 1px $black;
    // }
}

.error {
    color: $errorColor;
}

.success {
    color: $successColor;
}

img {
    image-rendering: optimize-contrast;
    max-width: 100%;
}

main {
    display: block;
}
