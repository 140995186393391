.template-partners {
    .partners-fixed {
        position: fixed;
        top: 57px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        pointer-events: none;

        svg {
            max-width: 123px;
            width: 100%;
            position: absolute;
            bottom: 30px;
            left: 30px;
            height: auto;
            opacity: 0;
        }

        @include respond-to('mobile') {
            position: relative;
            transform: translateX(0);
            top: 0;
            left: 0;
        }
    }

    .partners-header {
        height: calc(100vh - 57px);
        background-color: $brownLight;
        width: 38%;
        padding: 20px 25px;

        @include respond-to('mobile') {
            height: auto;
            width: 100%;
            // padding-bottom: 107px;
            min-height: 290px;
        }
    }

    .partners-content {
        // padding-left: 30%;
        display: flex;

        &__spacer {
            width: 38%;
            height: 1px;
            flex-grow: 1;
        }

        &__body {
            width: 62%;
            flex-grow: 1;
        }

        @include respond-to('mobile') {
            display: block;

            &__spacer {
                display: none;
            }

            &__body {
                width: 100%;
            }
        }
    }

    .partners-image {
        @include aspect-ratio(730, 630);
        @include background-cover;
        border-left: solid 1px $black;

        @include respond-to('mobile') {
            border-left: 0;
            border-top: solid 1px $black;
        }
    }
}

.template-partners-content {
    &__grid {
        @include flex-grid(2, 0, 0);
    }

    &__column {
        flex-basis: 62%;

        &:first-child {
            flex-basis: 38%;
        }
    }

    @include respond-to('mobile') {
        &__grid {
            display: block;
        }
    }
}

.template-partners-items {
    padding: 30px;
    padding-bottom: 60px;
    width: 100%;
    border-top: 1px solid $black;
    border-left: 1px solid $black;

    &__title {
        @include font-style-menu-item;
        text-transform: uppercase;
        margin-bottom: 100px;
    }

    &__grid {
        @include flex-grid(3, 50px, 0);
    }

    &__column {
        &:nth-child(3n + 2) {
            transition-delay: 150ms;
        }

        &:nth-child(3n + 3) {
            transition-delay: 300ms;
        }
    }

    &__image {
        @include background-contain;
        padding-top: 45%;
        max-width: 200px;
        margin: 0 auto;
    }

    @include respond-to('mobile') {
        border-left: 0;
        padding: 20px;

        &__title {
            margin-bottom: 60px;
        }

        &__grid {
            @include flex-grid(2, 40px, 20px);
        }

        &__column {
            &:nth-child(3n + 2) {
                transition-delay: 0ms;
            }

            &:nth-child(3n + 3) {
                transition-delay: 0ms;
            }

            &:nth-child(2n + 1) {
                transition-delay: 0ms;
            }

            &:nth-child(2n + 2) {
                transition-delay: 150ms;
            }
        }

        &__image {
            max-width: 140px;
        }
    }
}
