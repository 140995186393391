// .front-page {
// }

.home-header {
    @include aspect-ratio(1920, 1000);
    @include background-cover;
    position: relative;
    min-height: 100vh;
    z-index: 10;
    border-bottom: solid 1px $black;

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &__top-text {
        @include font-style-h2;
        position: absolute;
        max-width: 400px;
        left: 60.8%;
        top: 80px;
        color: $white;
    }

    &__marquee {
        width: 100%;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        padding-bottom: 30px;
        padding-right: 50px;

        p {
            color: $white;
            font-size: 200px;
            line-height: 1;
            text-transform: uppercase;
        }
    }

    .swiper-wrapper {
        transition-timing-function: linear;
    }

    .swiper-slide {
        width: auto;
        padding-right: 400px;
    }

    @include respond-to('tablet') {
        &__marquee {
            p {
                font-size: 150px;
            }

            svg {
                height: 150px;
                width: auto;
            }
        }
    }

    @include respond-to('mobile') {
        min-height: 60vh;
        border-bottom: 0;

        &__top-text {
            @include font-style-h1;
            left: 20px;
        }

        &__marquee {
            padding-bottom: 10px;

            p {
                font-size: 100px;
            }

            svg {
                height: 75px;
                width: auto;
            }
        }
    }
}
